export default {
  address: {
    contactNumberLength: 10, // include start with zero or not
    contactNumberMinLength: 10,
    contactNumberStartWithZero: false,
    emailRequired: true,
    hidePin: false,
    hideCountry: true,
    fixCountry: 'TH',
    pinCodeRequired: true,
    pinCodeLength: 6,
    pinCodeDigitOnly: true,
    addressLine2Mandatory: false,
  },
  allowInsufficientPoint: false,
  app: 'darwin',
  applyBgColorFromDjango: false,
  appName: 'Darwin',
  contactInfo: {
    en: '',
    th: '',
  },
  customFeeds: true,
  customRewardCategory: true,
  defaultCurrency: 'HKD',
  defaultLanguage: 'en',
  defaultUserIcon: 'assets/img/user.png',
  editFormDisabled: true,
  employeeIdPrefix: '',
  enableNomination: false,
  enforceDefaultLanguage: true,
  expiryPoint: true,
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  freshchat: {
    isShow: false,
    pages: '',
  },
  forgotPasswordLink: false,
  footer: {
    dynamicLogo: false,
    logoLink: '',
    privacyLink: '',
    tncLink: '',
    userGuide: '',
    showContactUS: false,
  },
  goToSsoLinkOnLogout: true,
  header: {
    dynamicBg: false,
    dynamicLogo: false,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: true,
  },
  hideSFStoreSFLocker: true,
  home: {
    featuredRewards: false,
    topRewards: true,
    recommendation: false,
  },
  languageAvailable: ['en'],
  languageLabel: {
    en: 'English',
  },
  leftNavigate: null,
  loginReadMore: false,
  loginTermsCondition: true,
  isSSOLoginSupport: true,
  modules: {
    home: false,
    appreciate: false,
    feeds: false,
    rewards: true,
    myWallet: false,
    settings: true,
    pointsHistory: false,
    cart: true,
    receipts: false,
    leaderboard: false,
  },
  panel: {
    link: ['/profile/edit'],
    support: false,
    dealerManagement: true,
  },
  profileEdit: {
    editFormDisabled: true,
    navigateToHome: false,
    departmentName: false,
    officialTitle: false,
    taxId: false,
    taxPercentage: false,
    registrationDate: false,
  },
  rating: false,
  recognitionRecommendation: false,
  registerNewAccount: false,
  rewardDefaultSort: 'lh',
  rewardDetailBackRoute: ['/home'],
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: false,
      rating: false,
    },
    sorting: true,
    title: 'common.reward.n',
  },
  settings: {
    hideMyAppreciationMessagesToggle: false,
    makeMyBirthdayPublicToggle: false,
    makeMyAnniversaryPublicToggle: false,
    makeMyProfilePublicToggle: false,
    passwordChangeShow: true,
    passwordChangeStyle: 'float',
  },
  showDepartmentName: true,
  showEmployeeId: true,
  showExpiringPoints: true,
  showSpecialDeal: false,
  showSessionTimer: true,
  showSessionTimerNote: true,
  showBackToWebsiteBtn: true,
  showUsername: false,
  sideCarousel: [],
  topMenu: true,
};

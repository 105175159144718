export default {
  dev: {
    admin: 'https://shashi.skordev.com/admin-dashboard/#/report',
    app: 'https://shashi.skordev.com/',
    basePath: 'https://darwin-pwa.skordev.com/#/',
    img: 'https://shashi.skordev.com',
    SSOUrl: 'https://demo.darwin.com/RewardCentre/login',
  },
  prod: {
    admin: 'https://cerrapoints.com/admin-dashboard/#/report',
    app: 'https://cerrapoints.com/',
    basePath: 'https://darwin-pwa.cerrapoints.com/#/',
    img: 'https://cerrapoints.com',
    SSOUrl: 'https://cerrapoints.com/profiles/sso/darwin/pwa-sso',
  },
};

<mat-drawer-container class="height-100" autosize [hasBackdrop]="hasBackdrop">
  <mat-drawer #leftDrawer class="sidenav--left" [mode]="mode">
    <ng-content
      select="[left-drawer]"
      [ngClass]="hideLeftDrawer ? 'hide' : ''"
    ></ng-content>
  </mat-drawer>
  <mat-drawer #rightDrawer class="sidenav--right" [mode]="mode" position="end">
    <app-point-detail
      *ngIf="appName !== 'BSTL'"
      [ngClass]="hideRightDrawer ? 'hide' : ''"
    ></app-point-detail>
    <ng-content *ngIf="hideRightDrawer" select="[right-drawer]"></ng-content>
  </mat-drawer>
  <div
    class="d-flex flex-column page-content"
    [ngClass]="hideFooter ? 'margin-bottom' : ''"
  >
    <app-service-banner
      *ngIf="homePage && showServiceBanner"
    ></app-service-banner>
    <app-header
      *ngIf="!hideHeader"
      [sticky]="stickyHeader"
      (toggleDrawer)="toggleDrawer($event)"
      [segments]="breadcrumb"
    ></app-header>
    <div class="div--container">
      <div class="container body-container">
        <div class="content" [ngStyle]="style">
          <app-timer
            *ngIf="showMobileTimer"
            class="d-block d-lg-none"
          ></app-timer>
          <label
            class="h6 my-2 text-danger fw-bolder"
            *ngIf="showSessionTimerNote"
            >{{ "sessionTimerNote" | translate }}
            <span class="secondary-color"
              >( {{ "inactiveSessionTimerNote" | translate }} )</span
            >
          </label>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <app-footer *ngIf="!hideFooter" [sticky]="stickyFooter"></app-footer>
    <app-bottom-menu
      *ngIf="showBottomMenu"
      class="d-block d-lg-none"
    ></app-bottom-menu>
  </div>
  <div
    class="support-icon d-flex justify-content-center align-items-center"
    *ngIf="supportChat.isShow"
    (click)="openSupportChat()"
  >
    <i class="bi bi-whatsapp"></i>
  </div>
</mat-drawer-container>
